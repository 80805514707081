<template>
  <div id="container">
    <v-card style="width: 100%; height: 80vh;" flat>
      <canvas id="line-chart"></canvas>
    </v-card>
  </div>
</template>

<script>
import Chart from "chart.js";
import moment from "moment";
import { mdiAccountSupervisor } from '@mdi/js';

export default {
  name: "LineChart",
  data() {
    return {
      gradient: [],
      data: [],
      filterData: {},
      startDate: "",
      endDate: "",
      tempResult: "",
      resultAmount: [],
      resultDate: [],
      newChart: null
    };
  },
  props: {
    chartColors: {
      type: Object,
    },
    filterSales: {
      type: Object,
    },
  },
  watch: {
    salesData: function(newVal, oldVal) {
      if(newVal.length != oldVal.length){
        if(this.newChart != null ){
          this.newChart.data.labels = []
          this.newChart.data.datasets[0].data = []
          this.salesData.map((sale) => {
            let changeDateFormat;
            changeDateFormat = moment(sale.day, "YYYYMMDD").format("YYYY/MM/DD");
            let { total_order_price } = sale;
            this.newChart.data.labels.push(changeDateFormat);
            this.newChart.data.datasets[0].data.push(total_order_price);
          });
          this.newChart.update()
        }
      }
    },
  },
  created() {
    this.renderLineChart();
  },
  computed: {
    salesData(){
      return this.$store.state.sales_module.salesResult
    },
  },
  methods: {
     dispatchSales() {
      // this.result =  this.$store.dispatch("sales_module/fetchSales");
      // return this.result;
    },
    async renderLineChart() {
      // await this.dispatchSales();
      await this.salesData.map((sale) => {
        let changeDateFormat;
        changeDateFormat = moment(sale.day, "YYYYMMDD").format("YYYY/MM/DD");
        let { total_order_price } = sale;
        this.resultDate.push(changeDateFormat);
        this.resultAmount.push(total_order_price);
      });

      let line_ctx = document.getElementById("line-chart").getContext("2d");
      let gradient = line_ctx.createLinearGradient(0, 0, 0, 300);
      gradient.addColorStop(0, "#0e1554");
      gradient.addColorStop(0.3, "#0e1554");
      gradient.addColorStop(0.8, "#3949AB");
      gradient.addColorStop(0.6, "#303F9F");
      gradient.addColorStop(1, "#2c3b96");

      this.newChart = new Chart(line_ctx, {
        type: "line",
        data: {
          labels: [],//this.resultDate,
          datasets: [
            {
              label: this.$t("message.total-sales"),
              data: [],//this.resultAmount,
              backgroundColor: "transparent",
              borderColor: "#0e1554",
              pointBorderColor: "#0e1554",
              pointBackgroundColor: "transparent",
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 1,
          scales: {
            yAxes: [
              // {display: true},
              {
                ticks: {
                  fontColor: "rgba(0,0,0,0.5)",
                  fontStyle: "bold",
                  beginAtZero: true,
                  padding: 20,
                  autoSkip: true,
                  maxTicksLimit: 5,
                },
                gridLines: {
                  drawTicks: false,
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  zeroLineColor: "transparent",
                  // display: false,
                },
                ticks: {
                  padding: 20,
                  fontColor: "rgba(0,0,0,0.5)",
                  fontStyle: "bold",
                },
              },
            ],
          },
        },
      });
    },
  },
};
</script>
<style scoped></style>
