<template>
  <div>
    <h4 class="mb-5">{{ $t("message.label-order-details") }}</h4>
    <v-row no-gutters align="center" justify="space-between">
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12" md="3" xs="4">
            <span>{{ $t("message.shop") }}</span>
            <v-autocomplete
              :items="storeListData"
              item-text="store_name"
              item-value="id"
              v-model="filtered.storeId"
              outlined
              class="input-class mt-1"
              style="font-size: 14px;"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" xs="4">
            <span>{{ $t("message.order-or-delivery") }}</span>
            <v-select
              :items="typeOrderDelivery"
              item-text="text"
              item-value="value"
              class="input-class mt-1"
              v-model="filtered.type"
              outlined
              style="font-size: 14px;"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" xs="6">
            <span>{{ $t("message.label-filter-request-datetime") }}</span>
            <v-row no-gutters class="row-amount">
              <v-col cols="12" md="5">
                <v-menu
                  v-model="modalDateFrom"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    class="mt-1"
                    outlined
                    clearable
                      v-model="filtered.dateFrom"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 14px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filtered.dateFrom"
                    @input="modalDateFrom = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col class="minus-amount">
                <v-icon class="icon-tilde-class">mdi-tilde</v-icon>
              </v-col>

              <v-col cols="12" md="5">
                <v-menu
                  v-model="modalDateTo"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    class="mt-1"
                    outlined
                    clearable
                      v-model="filtered.dateTo"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 14px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filtered.dateTo"
                    @input="modalDateTo = false"
                  ></v-date-picker>
                </v-menu>
                <!-- <v-dialog
                  ref="dialog"
                  v-model="modalDateTo"
                  :return-value.sync="filtered.dateTo"
                  persistent
                  width="290px"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filtered.dateTo"
                      class="mt-1"
                      outlined
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 14px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filtered.dateTo" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modalDateTo = false">
                      {{ $t("message.cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(filtered.dateTo)"
                    >
                      {{ $t("message.ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog> -->
              </v-col>
            </v-row>
          </v-col>

          <!-- <v-col cols="12" md="6" xs="4" class="mt-1">
            <v-label>{{ $t("message.datetime") }}</v-label>
            <v-dialog
              ref="dialog"
              v-model="modal"
              persistent
              width="290px"
              @click:outside="clickOutside"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  class="input-class mt-1"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker v-model="filtered.dates" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  {{ $t("message.cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(filtered.dates)"
                >
                  {{ $t("message.ok") }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col> -->

          <!-- <v-col cols="12" md="4" xs="4" >
            <v-label>{{ $t("message.per-item-order") }}</v-label>
            <v-select
              :items="formatSales"
              class="input-class mt-1"
              item-text="text"
              item-value="id"
              outlined
              style="font-size: 14px;"
            ></v-select>
          </v-col> -->
        </v-row>
      </v-col>

      <v-col cols="12" md="3">
        <v-row class="d-flex" justify="end">
          <v-col cols="12" md="6" xs="4" style="width: fit-content">
            <v-btn rounded color="primary" @click.prevent="submitFilter">
              {{ $t("message.reload") }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" style="width: fit-content; padding-left: 0px">
            <v-btn rounded color="primary" @click="exportCsv">
              {{ $t("message.download") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import { mdiTilde } from "@mdi/js";
import moment from "moment";
import axios from "../axios/axios";

export default {
  name: "FilterSalesOrderDetails",
  data: () => ({
    date: "",
    menu2: false,
    iconSearch: mdiMagnify,
    iconTilde: mdiTilde,
    items: [
      { id: "1", text: "shop 1" },
      { id: "2", text: "shop 2" },
    ],
    modalDateFrom: false,
    modalDateTo: false,
    modal: false,
    filtered: {
      type: { id: "2", value: "orderdate" },
      selectStatus: "",
      dateFrom: "",
      dateTo: "",
      storeId: "",
    },
    store: 0,
    resSales: [],
  }),
  watch: {},

  computed: {
    typeOrderDelivery() {
      return [
        {
          id: "1",
          text: this.$t("message.delivery-date"),
          value: "deliverydate",
        },
        { id: "2", text: this.$t("message.order-date"), value: "orderdate" },
      ];
    },
    formatSales() {
      return [
        { id: "2", text: this.$t("message.per-item") },
        { id: "3", text: this.$t("message.per-order") },
      ];
    },
    storeListData() {
      let storeResult = this.$store.state.shop_module.shopAllResult;
      return storeResult;
    },
  },
  methods: {
    // setupDate() {},
    exportCsv() {
      return this.$store.dispatch("sales_module/exportCsv", {
        storeId: this.filtered.storeId !== null ? this.filtered.storeId : "",
        dateType: this.filtered.type.value,
        datefrom: this.filtered.dateFrom !== null ? this.filtered.dateFrom : "",
        dateto: this.filtered.dateTo !== null ? this.filtered.dateTo : "",
      });
    },
    clickOutside() {
      this.modal = false;
    },
     dispatchSearch(){
       this.$store.dispatch("sales_module/getSales", {
        store_id: this.filtered.storeId !== null ? this.filtered.storeId : "",
        dateType: this.filtered.type.value,
        datefrom: this.filtered.dateFrom !== null ? this.filtered.dateFrom : "",
        dateto: this.filtered.dateTo !== null ? this.filtered.dateTo : "",
      });
    },
    async initFilter() {
      await this.$store.dispatch("shop_module/fetchShopAll");
      this.store = this.$store.state.shop_module.shopAllResult;
      this.filtered.storeId = this.store[0].id;
      this.dispatchSearch()
    },
    submitFilter() {
      this.dispatchSearch()
    },
  },
  mounted() {},
  created() {
    this.initFilter();
  },
};
</script>

<style>
.filter-input-class .v-input__slot .v-text-field__slot input{
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot .v-select__selections input {
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot input {
  padding: 8px 0px 8px 0px;
}
.font-size {
  font-size: 12px !important;
}
.minus-amount {
  max-width: fit-content;
}
.minus-amount .icon-tilde-class {
  font-size: 15px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 8px;
}
.btn-search {
  border-radius: 20px;
}
.v-input__control .v-input__slot .v-text-field__slot input {
  /* width: 70px; */
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
</style>
