<template>
  <div>
    <v-card max-width="344" flat>
      <!-- <h3>{{$t('message.daily')}}</h3> -->
      <table id="table">
        <thead>
          <tr class="tr-thead">
            <th class="th-label-class">{{ $t("message.total") }}</th>
            <th class="th-total-class">{{ sumTotal }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("message.sales") }}</td>
            <td class="td-value-class">{{ sumSales }}</td>
          </tr>
          <tr>
            <td>{{ $t("message.delivery-fee") }}</td>
            <td class="td-value-class">{{ sumOngkir }}</td>
          </tr>
          <tr>
            <td>{{ $t("message.point") }}</td>
            <td class="td-value-class">{{ sumPoint }}</td>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TableSales",
  props: {
    filterSales: {
      type: Object,
    },
  },
  data: () => ({
    sumTotal: null,
    sumSales: null,
    sumOngkir: null,
    sumPoint: null,
    filteredData: [],
  }),
  watch: {
    productsData: function(newVal, oldVal) {
      // this.renderTable();
      if (newVal.length !== oldVal.length) {
        this.sumSales = 0
        this.sumOngkir = 0
        this.sumTotal = 0
        this.sumPoint = 0
        this.productsData.map((el) => {
        this.sumSales += parseInt(el.total_order_price);
        this.sumOngkir += parseInt(el.service_price);
        this.sumPoint += parseInt(el.point);
      });
      this.sumTotal = this.sumSales + this.sumOngkir + this.sumPoint;
      }
     
    },
  },
  computed: {
    productsData() {
      return this.$store.state.sales_module.salesResult;
    },
  },
  mounted() {
    this.renderTable();
  },
  methods: {
    // async dispatchSales() {
    //   let dispatch = await this.$store.dispatch("sales_module/fetchSales");
    //   return dispatch;
    // },
    async renderTable() {
      // await this.dispatchSales();
      
      this.productsData.map((el) => {
        this.sumSales += parseInt(el.total_order_price);
        this.sumOngkir += parseInt(el.service_price);
        this.sumPoint += parseInt(el.point);
      });
      this.sumTotal = this.sumSales + this.sumOngkir + this.sumPoint;
    },
  },
};
</script>

<style scoped>
#total {
  text-align: right;
}
thead tr th {
  color: grey !important;
  background: white !important;
}
.th-label-class {
  font-size: 24px !important;
  padding-right: 2em !important;
}
.th-total-class {
  font-size: 25px !important;
}
thead {
  border-bottom: 2px solid grey !important;
}
.td-value-class {
  text-align: end !important;
}

.v-application .text-start,
tr td {
  text-align: start !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
</style>
