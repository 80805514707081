<template>
  <div id="chart">
    <apexchart
      type="pie"
      width="750"
      :options="chartOptions"
      :series="resultData"
    ></apexchart>
  </div>
  <!-- <div>
    <canvas id="pie-chart"></canvas>
  </div> -->
</template>

<script>
import Chart from "chart.js";
import VueApexCharts from "vue-apexcharts";
export default {
  name: "PieChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    filterSales: {
      type: Object,
    },
  },
  data() {
    return {
      resultData: [],
      chartOptions: {
        colors: ["#C5CAE9", "#7986CB", "#5C6BC0", "#3949AB", "#283593"],
        chart: {
          width: 700,
          type: "pie",
        },
        height: 500,
        width: "100%",
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
        },
        legend: {
          position: "right",
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 100,
                height: 100,
              },
            },
          },
        ],
      },
    };
  },
  watch: {
    productsData: function(newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        let itemName = [];
        let itemSale = [];
        this.productsData.map((el) => {
          if (el.total_sales > 0) {
            itemSale.push(parseInt(el.total_sales));
            itemName.push(el.item_name);
            this.resultData.splice(0, this.resultData.length, ...itemSale);
            this.chartOptions.labels.splice(
              0,
              this.chartOptions.labels.length,
              ...itemName
            );
          }
        });
        if(newVal.length == 0){
          this.resultData = [1]
          this.chartOptions.labels.splice(
              0,
              this.chartOptions.labels.length,
              'not found'
            );
        }
      }
    },
  },
  mounted() {
    this.renderPieData();
  },
  computed: {
    productsData() {
      return this.$store.state.sales_module.productResult;
    },
  },
  methods: {
    // async dispatchSales() {
    //   let dispatch = await this.$store.dispatch("sales_module/fetchSales");
    //   return dispatch;
    // },
    async renderPieData() {
      let itemName = [];
      let itemSale = [];
      // await this.dispatchSales();
      this.productsData.map((el) => {
        if (el.total_sales > 0) {
          itemSale.push(parseInt(el.total_sales));
          itemName.push(el.item_name);
          this.resultData.splice(0, this.resultData.length, ...itemSale);
          this.chartOptions.labels.splice(
            0,
            this.chartOptions.labels.length,
            ...itemName
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.canvas-class {
  padding: inherit !important;
}
</style>
