<template>
  <v-container>
    <h4 class="mb-5">{{ $t("message.label-order-details") }}</h4>

    <!-- <FilterSalesOrderDetails class="mb-5" /> -->
    <FormFilterSales class="mb-5" :type="'orderDetails'" />


    <!-- <LineChart  :salesData="salesData"  :amount="resultAmount" :date="resultDate"/> -->
    <!-- <v-col>
        <PieChart :filterSales="PieChartFilter" />
      </v-col> -->
    <!-- <v-row class="mt-5">
      <v-col md="3">
        <TableSales :filterSales="tableData"/>
      </v-col>
    </v-row> -->

    <v-data-table
      :headers="headers"
      :items="salesOrder"
      class="elevation-0"
      hide-default-header
      items-per-page-all-text
      :hide-default-footer="true"
      disable-pagination
    >
      <template v-slot:header="{ props }" style="min-height: 50px">
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[0].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[1].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[2].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[3].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[4].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 120px">
          {{ props.headers[5].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[6].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[7].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[8].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[9].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[10].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[11].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 250px">
          {{ props.headers[12].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[13].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[14].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[15].text }}
        </th>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import LineChart from "@/components/LineChart.vue";
import PieChart from "@/components/PieChart.vue";
import FilterSalesOrderDetails from "@/components/FilterSalesOrderDetails.vue";
import TableSales from "@/components/TableSales.vue";
import FormFilterSales from '../components/FormFilterSales.vue'
import moneyFormat from '../helpers/moneyFormat'

export default {
  name: "SalesOrderDetails",
  components: {
    LineChart,
    PieChart,
    FilterSalesOrderDetails,
    TableSales,
    FormFilterSales
  },
  props: {},
  data: () => ({
    tableData: {},
    lineChartData: {},
    PieChartFilter: {},
    resultDate: [],
    resultAmount:[],
    result: [],
  }),
  created() {
    // this.dispatchSales();
    this.dispatchSalesOrder()
  },
  watch:{},
  computed: {
    // salesData(){
    //   return this.$store.state.sales_module.salesResult
    // },
    // productsData(){
    //   return this.$store.state.sales_module.productResult
    // },
    salesOrder(){
      let salesData = this.$store.state.sales_module.salesOrder
      if(salesData) {
        salesData.map((sales) => {
          sales.total_order_price = moneyFormat(parseInt(sales.total_order_price))
          sales.price = moneyFormat(parseInt(sales.price))
          sales.priceXqty = moneyFormat(parseInt(sales.priceXqty))
        })
      }
      return salesData
    },
    headers() {
      return [
        { text: "ID", align: "center", value: "order_id" },
        {
          text: this.$t("message.label-invoice"),
          value: "invoice_no",
          align:"center",
        },
        { text: this.$t("message.order-total-fee"), value: "total_order_price", align:"center", },
        { text: this.$t("message.label-item_id"), align: "center", value: "item_id" },
        { text: this.$t("message.product-name"), align: "center", value: "item_name" },
        { text: this.$t("message.item-code"), align: "center", value: "item_code" },
        { text: this.$t("message.label-price"), align: "center", value: "price" },
        { text: this.$t("message.label-tax-amount"), align: "center", value: "tax" },
        { text: this.$t("message.label-qty_actual"), align: "center", value: "qty_actual" },
        { text: this.$t("message.label-qty"), align: "center", value: "qty" },
        { text: this.$t("message.label-amount-excluding-tax"), align: "center", value: "priceXqty" },
        { text: this.$t("message.label-tax-amount"), align: "center", value: "taxXqty" },
        { text: this.$t("message.label-tax_category"), align: "center", value: "tax_category" },
        { text: this.$t("message.label-tax_rate"), align: "center", value: "tax_rate" },
        { text: this.$t("message.order-date"), align: "center", value: "created_at" },
        { text: this.$t("message.label-delivery-date"), align: "center", value: "delivery_datetime" },
      ];
    }
  },
  methods: {
    // dispatchSales(){
    //   return this.$store.dispatch('sales_module/fetchSales')
    // },
    dispatchSalesOrder(){
      return this.$store.dispatch('sales_module/fetchSalesOrderDetails')
    }
  },

};
</script>

<style scoped>
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
/* #total {
  text-align: right;
} */
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}
/* thead tr th {
  color: grey !important;
  background: white !important;
}
.th-label-class {
  font-size: 24px !important;
  padding-right: 2em !important;
}
.th-total-class {
  font-size: 30px !important;
}
thead {
  border-bottom: 2px solid grey !important;
}
.td-value-class {
  text-align: end !important;
}

.v-application .text-start,
tr td {
  text-align: start !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
} */
</style>
